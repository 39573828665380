import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`コンプの内容を実践するときに、`}<br parentName="p"></br>{`
`}{`本教材で推奨しているコンプは`}<br parentName="p"></br>{`
`}{`Studio One付属の`}<br parentName="p"></br>{`
`}{`「Channel Strip」というプラグインのコンプです。`}</p>
      <p>{`Studio Oneに付属の「Compressor」や、`}<br parentName="p"></br>{`
`}{`動画で使用している「Pro C2」を`}<br parentName="p"></br>{`
`}{`使おうとする方が多くおられますが、`}<br parentName="p"></br>{`
`}{`75点が確実に出せるようになるまでは`}<br parentName="p"></br>{`
`}<strong parentName="p">{`控えるべきです。`}</strong></p>
      <p>{`なぜなら、例えばスタジオワン付属の「Compressor」を使った場合は、設定するべきパラメータが多く、Adaptive releaseのように副作用を生む機能が最初からオンになっていたりするので、MIXを学ぶ段階で使うには適していません。`}<br parentName="p"></br>{`
`}{`その点、「Channel Strip」なら、スレッショルドを１つ設定するだけで、ほぼ済んでしまいます。（実際は、アタックも3種類から選べますが、ほぼデフォルトのままで通用します。この辺りの具体的な設定の仕方については「知識編」ではカバーしきれませんので、「実践編」の範囲となります）`}</p>
      <p>{`ですので、ただChannel Stripに変えるだけで、`}</p>
      <p>{`「Kneeって何や？」`}<br parentName="p"></br>{`
`}{`「Autoって何や！？」`}<br parentName="p"></br>{`
`}{`「Look AheadとかSideChainって何や？？！」`}</p>
      <p>{`という無駄な悩みを、全部すっ飛ばせる可能性があります。`}</p>
      <p>{`何度も強調していますが、ほとんどの場合MIXを苦しくしているのは、こうした不必要なパラメータです。ですので、最初から複雑なものに手を出して、自分から泥沼にハマるようなことはどうぞしないでください。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      